(function () {
  "use strict";

  angular.module("garanteasyApp").config(translationConfig);

  translationConfig.$inject = [
    "$translateProvider",
    "tmhDynamicLocaleProvider"
  ];

  function translationConfig($translateProvider, tmhDynamicLocaleProvider) {
    // Initialize angular-translate
    $translateProvider.useLoader("$translatePartialLoader", {
      urlTemplate: "i18n/{lang}/{part}.json"
    });

    $translateProvider.useStorage("translationStorageProvider");
    $translateProvider.useSanitizeValueStrategy("escaped");
    $translateProvider.addInterpolation("$translateMessageFormatInterpolation");
    $translateProvider.registerAvailableLanguageKeys(['it', 'en', 'de', 'fr', 'es', 'pt'], {
      'it_*': 'it',
      'en_*': 'en',
      'de_*': 'de',
      'fr_*': 'fr',
      'es_*': 'es',
      'pt_*': 'pt',
      '*': 'en'
    })
      .useMissingTranslationHandlerLog()
      .determinePreferredLanguage();

    tmhDynamicLocaleProvider.localeLocationPattern(
      "i18n/angular-locale_{{locale}}.js"
    );
    tmhDynamicLocaleProvider.useCookieStorage();
    tmhDynamicLocaleProvider.storageKey("NG_TRANSLATE_LANG_KEY");
  }
})();
